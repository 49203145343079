import React, { useState, useEffect } from "react";
import Layout from "@Components/Layout";
import Skills from "@Components/Skills";
import styled from "styled-components";
import Baseline from "@Components/Baseline";
import SEO from "@Components/SEO";
import { useStaticQuery, graphql } from "gatsby";

const SkillsPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #0b0b0b;
  overflow: visible;
  position: relative;
  z-index: 9000;
`;

export default function SkillsPage() {
  let data = useStaticQuery(graphql`
    query {
      config: contentfulConfig(configuration: { eq: "Site configuration" }) {
        studioCreatif
        event
        digital
        branding
        baseline {
          baseline
        }
        subBaseline {
          subBaseline
        }
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 1024) {
      setIsMobile(true);
    }
  }, []);

  function setPage() {
    document.querySelector("html").style.overflowY = "scroll";
  }

  React.useEffect(() => setPage(), []);

  return (
    <Layout
      menuOpen={true}
      menuVisible={true}
      location={"skills"}
      textIsRed={true}
    >
      <SEO title="Skills" />
      <SkillsPageWrapper>
        <Skills
          isMobile={isMobile}
          infosWordings={{
            branding: data.config.branding,
            event: data.config.event,
            digital: data.config.digital,
            studioCreatif: data.config.studioCreatif,
          }}
        />
      </SkillsPageWrapper>
      <Baseline
        wording={{
          baseline: data.config.baseline.baseline,
          subBaseline: data.config.subBaseline.subBaseline,
        }}
      />
    </Layout>
  );
}
